import { Component, OnInit } from "@angular/core";
// declare var Rellax: any;
@Component({
  selector: "app-about-section",
  templateUrl: "./about-section.component.html",
  styleUrls: ["./about-section.component.css"],
})
export class AboutSectionComponent implements OnInit {
  constructor() {}

  ngOnInit() {
    // var rellax = new Rellax(".rellax");
  }
}
