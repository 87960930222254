import { Component, OnInit } from "@angular/core";
// declare var Rellax: any;
@Component({
  selector: "app-contact-section",
  templateUrl: "./contact-section.component.html",
  styleUrls: ["./contact-section.component.css"],
})
export class ContactSectionComponent implements OnInit {
  constructor() {}

  ngOnInit() {
    // var rellax = new Rellax(".rellax");
  }
}
